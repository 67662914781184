import notificationsSocket from './notificationsSocket';
import messagesSocket from './messagesSocket';
import socket from './socket';

export default function connectToSocket(token, user, connect) {
  if (connect) {
    socket.connectWithAuthToken(token);
    messagesSocket.connectWithAuthToken(token)
    notificationsSocket.connectWithAuthToken(token)
  } else {
    setTimeout(() => {
      window.dispatchEvent(new CustomEvent('first_login'))
    }, 1000);
  }

  // window.dispatchEvent(new CustomEvent('first_login'))

  localStorage.setItem('socket_connect_token', token)
}

export function disconnectFromSocket() {
  socket.disconnect();
  messagesSocket.disconnect()
  notificationsSocket.disconnect()
}